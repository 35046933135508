import { graphql, useStaticQuery } from "gatsby";

export default function QueryReleases(){
    const result = useStaticQuery(graphql`
        query {
            allStrapiProductUpdate {
                edges {
                    node {
                        id
                        Title
                        DatePublished
                        Content {
                            data {
                                Content
                            }
                        }
                        slug
                        locale
                        localizations {
                            data {
                                attributes {
                                    Title
                                    Content
                                    DatePublished
                                    locale
                                    slug
                                }
                            }
                        }
                    }
                }
            }
        }`
    )
    return result
};