import React from 'react';
import moment from 'moment';
import { upperFirst } from 'lodash';
import { withTranslation } from 'react-i18next'
import { Link } from "gatsby";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import { useTranslation } from 'react-i18next';

const ReleaseBox = (props) => {

        const { date, title, html, detailPage, slug, baseUrlImage } = props;
        const { i18n } = useTranslation();

        let style = {
            ...props.style,
            padding: 30,
            paddingBottom: 0
        };

        return (
            <div style={style}>
                <div className="mb20">
                    <div className="mb5" style={{ fontSize: 13 }}>{moment(date).format('LL')}</div>
                    <h2>
                        {!detailPage ? (
                            <Link to={i18n.language === "en" ? "/en/product-updates/" + slug + "/" : "/es/actualizaciones-producto/" + slug + "/"} style={{ color: 'black' }}>{upperFirst(title)}</Link>
                        ) :  upperFirst(title)}
                    </h2>
                </div>
                <div id="releaseBody">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} transformImageUri={uri => uri.startsWith('http') ? uri : `${baseUrlImage}${uri}`}>{html}</ReactMarkdown>
                </div>
            </div>
        );
};

export default withTranslation()(ReleaseBox);