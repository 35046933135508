import React from 'react';
import { withTranslation } from 'react-i18next'
import { Link } from "gatsby";
import { toLower } from 'lodash';
import { useTranslation } from 'react-i18next';

// Components
import MainLayout from '../../components/main-layout';
import HeaderSection from './header-section';
import ReleaseBox from './release-box';
import gatsbyConfig from '../../../gatsby-config';
import QueryReleases from './queryReleases';

const Release = (props) => {

    const { pageContext } = props,
    { t, i18n } = useTranslation();
    const strapiUrl = gatsbyConfig.siteMetadata.strapiUrl;
    const productUpdates = QueryReleases();
    const responseProductUpdates = productUpdates.allStrapiProductUpdate.edges;

    // We sort the product updates by publication date, starting with most recent dates
    const sortedProductUpdates = responseProductUpdates.sort(function(a, b) {
        a = new Date(a.node.DatePublished);
        b = new Date(b.node.DatePublished);
        return a > b ? -1 : a < b ? 1 : 0;
    });

    // We filter product updates by id
    const filterProductUpdates = sortedProductUpdates.filter(product => product.node.id === pageContext.id);
    const mapSortedProductUpdates = sortedProductUpdates.map(product => product.node.id);

    // We get the position in array
    const positionInArray = mapSortedProductUpdates.indexOf(filterProductUpdates[0].node.id);

    // Prev position
    const prev = sortedProductUpdates[positionInArray-1];

    // Next position
    const next = sortedProductUpdates[positionInArray+1];

    return (
        <MainLayout
            pageContext={pageContext}
        >
            <HeaderSection key="header" {...props}/>
            <div key="release-page" className="container">
                <div key="breadcrumbs" className="row mt20 mb10">
                    <div className="col">
                        <Link to={i18n.language === 'en' ? '/' : '/es/'} >
                            {t('mainMenuLinks.home')}
                        </Link>
                        <span className="mh10"> / </span>
                        <Link to={ i18n.language === 'en' ? '/en/product-updates/': i18n.language === 'es' ? '/es/actualizaciones-producto/' : null } >
                            {t('productUpdates.title')}
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ReleaseBox
                            date={pageContext.date}
                            title={pageContext.title}
                            html={pageContext.content}
                            style={{ marginBottom: 20 }}
                            slug={pageContext.slug}
                            detailPage={true}
                            baseUrlImage={strapiUrl}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center mv15 pv5 fs14" style={{ background: '#eceff1' }}>
                        {prev && (
                            i18n.language === "en" ? <Link to={"/en/product-updates/" + toLower(prev.node.slug + "/")} style={{ color: 'black' }}>{`< ${prev.node.Title || t('productUpdates.prevLabel')}`}</Link> :  
                                                    prev.node.localizations?.data[0] ? <Link to={"/es/actualizaciones-producto/" + toLower(prev.node.localizations?.data[0]?.attributes?.slug + "/")} style={{ color: 'black' }}>{`< ${prev.node.localizations?.data[0]?.attributes?.Title || t('productUpdates.prevLabel')}`}</Link> : ''
                                                     
                        )}

                        {prev && next && ( <span className="mh5"> | </span>)}

                        {next && (
                            i18n.language === "en" ? <Link to={"/en/product-updates/" + toLower(next.node.slug + "/")} style={{ color: 'black' }}>{`${next.node.Title || t('productUpdates.nextLabel')} >`}</Link> :
                                                     <Link to={"/es/actualizaciones-producto/" + toLower(next.node.localizations?.data[0]?.attributes?.slug + "/")} style={{ color: 'black' }}>{`${next.node.localizations?.data[0]?.attributes?.Title || t('productUpdates.nextLabel')} >`}</Link>
                        )}
                    </div>
                </div>
            </div>
        </MainLayout>
    )
};

export default withTranslation()(Release);